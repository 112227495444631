<template>
    <div>
        <router-view v-show="this.$route.matched.length==3"></router-view>
        <PageHeaderLayout v-show="this.$route.matched.length==2">
            <div class="main-page-content">
<!--                查询搜索-->
                <el-row class="table-search">
                    <el-form :inline="true" :model="searchCondition">
                        <el-form-item label="岗位编码">
                            <el-input v-model="searchCondition.post_code" placeholder="请输入岗位编码" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="岗位名称">
                            <el-input v-model="searchCondition.post_name" placeholder="请输入岗位名称" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="searchCondition.status" placeholder="请选择" clearable>
                                <el-option
                                        v-for="item in status_options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="searchButton">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="info" icon="el-icon-refresh" @click="resetButton">重置</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="showAddDialog"
                                       v-if="userPermissions.indexOf('sys_post_create') != -1">新增</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
<!--                表格-->
                <el-table
                        :data="postList"
                        style="width: 100%"
                        border
                        stripe
                        class="post_table"
                >
                    <el-table-column prop="id" label="岗位编号" align="center"></el-table-column>
                    <el-table-column prop="post_code" label="岗位编码" align="center"></el-table-column>
                    <el-table-column prop="post_name" label="岗位名称" align="center"></el-table-column>
                    <el-table-column prop="post_sort" label="岗位排序" align="center"></el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <p v-if="scope.row.status === '0' ">正常</p>
                            <p v-else>停用</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                    <el-table-column prop="created_at" label="创建时间" align="center"></el-table-column>
                    <el-table-column prop="post_code" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="primary" size="mini" icon="el-icon-edit" @click="showEditDialog(scope.row.id)" class="postEdit"
                                     v-if="userPermissions.indexOf('sys_post_edit') != -1"
                            >修改</el-link>
                            <el-link type="primary" size="mini" icon="el-icon-delete" @click="removePostById(scope.row.id)"
                                     v-if="userPermissions.indexOf('sys_post_delete') != -1"
                            >删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
<!--                分页-->
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="searchCondition.current_page"
                        :page-sizes="[10, 15, 20, 50]"
                        :page-size="searchCondition.page_size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                >
                </el-pagination>
<!--                添加对话框-->
                <el-dialog
                        title="添加岗位"
                        :visible.sync="addDialogVisible"
                        width="30%"
                        @close="addDialogClosed"
                >
                    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                        <el-form-item label="岗位名称" prop="post_name"><el-input v-model="addForm.post_name" placeholder="请输入岗位名称"></el-input></el-form-item>
                        <el-form-item label="岗位编码" prop="post_code"><el-input v-model="addForm.post_code" placeholder="请输入岗位编码"></el-input></el-form-item>
                        <el-form-item label="岗位顺序" prop="post_sort"><el-input-number v-model="addForm.post_sort" controls-position="right" @change="handleChange" :min="0"></el-input-number></el-form-item>
                        <el-form-item label="岗位状态">
                            <template>
                                <el-radio v-model="addForm.status" label="0">正常</el-radio>
                                <el-radio v-model="addForm.status" label="1">停用</el-radio>
                            </template>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="addForm.remark"
                            >
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="addDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="addPost">确 定</el-button>
                    </span>
                </el-dialog>
<!--                修改对话框-->
                <el-dialog
                        title="修改岗位"
                        :visible.sync="editDialogVisible"
                        width="30%"
                        @close="editDialogClosed"
                >
                    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                        <el-form-item label="岗位名称" prop="post_name"><el-input v-model="editForm.post_name" placeholder="请输入岗位名称"></el-input></el-form-item>
                        <el-form-item label="岗位编码" prop="post_code"><el-input v-model="editForm.post_code" placeholder="请输入岗位编码"></el-input></el-form-item>
                        <el-form-item label="岗位顺序" prop="post_sort"><el-input-number v-model="editForm.post_sort" controls-position="right" @change="handleChange" :min="0"></el-input-number></el-form-item>
                        <el-form-item label="岗位状态">
                            <template prop="status">
                                <el-radio v-model="editForm.status" label="0">正常</el-radio>
                                <el-radio v-model="editForm.status" label="1">停用</el-radio>
                            </template>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入内容"
                                    v-model="editForm.remark"
                            >
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="editDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="editPost">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>
  import PageHeaderLayout from '@/layouts/PageHeaderLayout'
  import ApeTable from '@/components/ApeTable'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      PageHeaderLayout,ApeTable
    },
    name: 'SysPost',
    data(){
      return{
        //查询条件
        searchCondition:{
          page_size:10,
          current_page:1,
          post_name:null,
          post_code:null,
          status: null
        },
        status_options: [{
          value: '0',
          label: '正常'
        }, {
          value: '1',
          label: '停用'
        }],
        //表格列表数据
        postList:[],
        total:0,
        //添加岗位对话框的显示或隐藏
        addDialogVisible:false,
        //添加岗位表单数据
        addForm:{
          post_name:'',
          post_code:'',
          status:'',
          post_sort:0,
          remark:''
        },
        //添加表单校验规则
        addFormRules:{
          post_name: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
          post_code: [{ required: true, message: '请输入岗位编码', trigger: 'blur' }],
          post_sort: [{ required: true, message: '请输入岗位顺序', trigger: 'blur' }],
        },
        //修改岗位对话框的显示和隐藏
        editDialogVisible:false,
        //修改岗位表单数据
        editForm:{},
        //修改表单校验规则
        editFormRules:{
          post_name: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
          post_code: [{ required: true, message: '请输入岗位编码', trigger: 'blur' }],
          post_sort: [{ required: true, message: '请输入岗位顺序', trigger: 'blur' }],
        },
      }
    },
    computed: {
      ...mapGetters(['userPermissions', 'buttonType'])
    },
    methods:{
      //获取岗位列表数据
      async getPostList(){
        const searchCondition = this.handleSearchCondition()
        const res = await this.$api.getPostList(searchCondition)
        this.postList = res.list
        this.total = res.pages.total
      },
      showAddDialog(){
        this.addDialogVisible = true
      },
      handleChange() {
        // console.log(value);
      },
      //添加岗位
      addPost(){
        this.$refs.addFormRef.validate( async (valid) => {
          if(!valid){
            return
          }
          await this.$api.saveSysPost(this.addForm)
          this.addDialogVisible = false
          await this.getPostList()
          this.$message.success('添加岗位成功')
        })
      },
      //监听添加用户对话框关闭事件
      addDialogClosed() {
        this.$refs.addFormRef.resetFields()
      },
      //监听page_size改变的事件
      handleSizeChange(pagesize) {
        this.searchCondition.page_size = pagesize
        this.getPostList()
      },
      //监听current_page的事件
      handleCurrentChange(pagenum) {
        this.searchCondition.current_page = pagenum
        this.getPostList()
      },
      //根据id删除岗位
      removePostById(id){
        this.$confirm('此操作将删除该岗位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await this.$api.deleteSysPost(id)
          console.log(res)
          await this.getPostList()
          this.$message.success('删除成功')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //根据id查询岗位信息
      showEditDialog(id){
        this.postList.some(item => {
          if(item.id === id){
            this.editForm = item
          }
          this.editDialogVisible = true
        })
      },
      //修改岗位
      editPost(){
        this.$refs.editFormRef.validate(async (valid) => {
          if(!valid){
            return
          }
          await this.$api.saveSysPost(this.editForm)
          this.editDialogVisible = false
          await this.getPostList()
          this.$message.success('修改岗位成功')
        })
      },
      //监听添加用户对话框关闭事件
      editDialogClosed() {
        this.$refs.editFormRef.resetFields()
      },
      // 搜索
      searchButton() {
        this.searchCondition.current_page = 1
         this.getPostList()
      },
      // 处理搜索条件
      handleSearchCondition() {
        let condition = {}

        if (this.searchCondition.post_name) {
          condition.post_name = this.searchCondition.post_name
        }
        if (this.searchCondition.post_code) {
          condition.post_code = this.searchCondition.post_code
        }
        if (this.searchCondition.status) {
          condition.status = this.searchCondition.status
        }
        if (this.searchCondition.page_size) {
          condition.page_size = this.searchCondition.page_size
        }
        if (this.searchCondition.current_page) {
          condition.current_page = this.searchCondition.current_page
        }

        return condition
      },
      //重置
      resetButton(){
        this.searchCondition.post_code = null
        this.searchCondition.post_name = null
        this.searchCondition.status = null
        this.getPostList()
      }
    },
    created(){
      this.getPostList()
    }
  }
</script>

<style  scoped>
.el-table{
    margin-top: 15px;
}
    .el-pagination{
        display: flex;
        justify-content: flex-end;
    }
    .postEdit{
        margin-right: 10px;
    }
</style>